import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import SocialSustainableBanner from "../components/socialSustainableBanner"

class SocialSustainabilityWorkingGroup extends Component {
    render() {
        return (
            <Layout headerElement={<SocialSustainableBanner />}>
                <SEO title="Community Service Working Group" />
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-box">
                                    <div className="img-box__img">
                                        <img className="img--bg m-b-10"  alt="health" src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/Family.png" style={{height: '42%', marginTop: 125}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 mt-5">
                                <h2 className="text-medium"><img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/mmere-dane-small.png" width="40" /> Community Services</h2>
                                <h5 style={{marginLeft: 50, marginBottom: 24}}>(Including child welfare, family services, housing)</h5>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px'}}>
                                    <li style={{marginBottom: 12}}>Racial disparity and disproportionality in the Child Welfare system – African Nova Scotians make up 2.3% of the general population but represent more than 10% of Child Welfare cases</li>
                                    <li style={{marginBottom: 12}}>Unequal availability of social services for African Nova Scotian children, youth, and families, especially in rural communities</li>
                                </ul>
                                <div className="widget-title recommended" style={{textDecoration: 'underline'}}>Recommendations</div>
                                <ul style={{fontSize: '1.2rem', lineHeight: '1.5', marginLeft: '32px', marginTop: 18}}>
                                    <li style={{marginBottom: 12}}>Create and use disaggregated data to address disparity and disproportionality</li>
                                    <li style={{marginBottom: 12}}>Implement strong culturally competent component in Child Welfare and hire more African Nova Scotian staff/supervisors at the screening, investigation and assessment levels</li>
                                    <li style={{marginBottom: 12}}>Recruit African Nova Scotian service providers, and enhance communication and collaboration with African Nova Scotian communities.</li>
                                </ul>
                                <div className="clear"></div>
                                <div className="seperator"><i className="fa fa-gavel"></i></div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        );
    }
}

export default SocialSustainabilityWorkingGroup